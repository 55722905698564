import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "../../../components/common/TextField";
import TextArea from "../../../components/common/TextArea";
import NumberInput from "../../../components/common/Number";
import Password from "../../../components/common/Password";
import RadioSelector from "../../../components/common/RadioSelector";
import ContentRenderer from "../../../components/common/contentRenderer";
import CheckBoxSelector from "../../../components/common/CheckBoxSelector";
import FormRendererContext from "../../../services/context/FormRendererContext";
import { newFormSubmit } from "../../../utils/form";
import SingleCheckBox from "../../../components/common/SingleCheckBox";
import DropDownSelector from "../../../components/common/DropDownSelector";
import DateInput from "../../../components/common/DateInput";
import Email from "../../../components/common/Email";
import Signature from "../../../components/common/Signature";
import UploadFile from "../../../components/common/UploadFile";
import Telephone from "../../../components/common/Telephone";
import {
  callAthenaGetApi,
  callAthenaPostApi,
  callAthenaPutApi,
} from "../../../utils/athena";
import DropDownWithSearch from "../../../components/common/DropDownWithSearch";
import HealthHistoryAllergyComponent from "../../../components/common/HealthHistoryAllergyComponent.jsx";
import axios from "axios";
import Loader from "../../../components/common/loader";
import ModalBox from "../../../components/common/modal";
import HealthHistoryMedicationComponent from "../../../components/common/HealthHistoryMedicationComponent.jsx";
import AmericanPhoneField from "../../../components/common/AmericanPhoneField.jsx";
import DynamicContentRenderer from "../../../components/common/DynamicContentRenderer.jsx";
import moment from "moment";

const timezoneMapper = {
  est: -5,
  cst: -6,
  mst: -7,
  pst: -8,
  akst: -9,
  hst: -10,
};

const FormRenderer = ({
  formData,
  handleNextScreenPage,
  handleBackScreenPage,
  activeFormPageData,
  firstPageFirstFormID,
  formClass,
}) => {
  const [formSchema, setFormSchema] = useState(null);
  const [formAnswer, setFormAnswer] = useState(null);
  const [activeFormPage, setActiveFormPage] = useState(null);
  const [activeFormPageId, setActiveFormPageId] = useState(null);
  const [showloader, setShowLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalSubtitle, setModalSubtitle] = useState("");

  const [formattedAnswer, setFormattedAnswer] = useState(null);
  const [qnaList, setQnaList] = useState([]);

  const [formValid, setFormValidation] = useState(true);

  const { answerData, setAnswerData, socialKeys } =
    useContext(FormRendererContext);
  const {
    journeyData,
    setJourneyData,
    activeScreenID,
    activeScreenPageID,
    completeJourneyAnswers,
    setCompleteJourneyAnswers,
    setHBxFirstName,
    setHBxLastName,
    setHBxEmail,
    setHBxDob,
    HBxReasonForVisit,
    setHBxReasonForVisit,
    journeyId,
    patientIdFromQP,
    appointmentIdFromQP,
    hashIdFromQP,
    allergyData,
    medicationData,
    patientTZ,
    setPatientTZ,
    insuranceCollectedStatus,
    paymentCollectedStatus,
    setScheduler_id,
  } = useContext(FormRendererContext);
  let navigate = useNavigate();

  const paymentForm = "66ec5b455b3a1419fb717b99";
  const insuranceForm = "66e918895b3a1419fb7132b7";

  async function uploadDemographics() {
    const demographicsAnswer = {
      firstName: answerData?.demographicsPatientFirstName?.value || "",
      middleName: answerData?.demographicsMiddleInitial?.value || "",
      lastName: answerData?.demographicsPatientLastName?.value || "",
      address: {
        streetAddress: answerData?.demographicsStreetAddress?.value || "",
        ZIP: parseInt(answerData?.demographicsZipCode?.value, 10) || "",
        city: answerData?.demographicsCity?.value || "",
        state: answerData?.demographicsState?.value || "",
        country: answerData?.demographicsCountry?.value || "",
      },
      phoneNumber: {
        home: answerData?.demographicsHomePhone?.value || "",
        work: answerData?.demographicsWorkPhone?.value || "",
        mobile: answerData?.demographicsMobilePhone?.value || "",
        // mobile: "(786) 767-7772demographicsMobilePhone",
      },
      sex: answerData?.demographicsLegalGender?.value || "",
      maritalStatus: answerData?.demographicsMaritalStatus?.value || "",
      email: answerData?.demographicsEmail?.value
        ? [answerData?.demographicsEmail?.value]
        : [],
      race: answerData?.demographicsRace?.value || "",
      ethnicity: answerData?.demographicsEthnicity?.value || "",
      SSN: answerData?.demographicsSsn?.value || "",
      language: answerData?.demographicsPreferredLanguage?.value || "",
      consentToCall:
        answerData?.demographicsConsentToCall?.value === "yes" ? true : false,
      consentToText:
        answerData?.demographicsConsentToTextYou?.value === "yes"
          ? true
          : false,
      emergencyContact: {
        name:
          answerData?.demographicsEmergencyFirstName?.value +
            " " +
            answerData?.demographicsEmergencyLastName?.value || "",
        phone: answerData?.demographicsEmergencyMobilePhone?.value || "",
        relationship:
          answerData?.demographicsEmergencyRelationship?.value || "",
      },
    };
    const API_URL = process.env.REACT_APP_SCHEDULER_URL;
    const putDemographicsUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/patient`;
    try {
      // submit demographiocs data
      const demographicResponse = await callAthenaPutApi(
        putDemographicsUrl,
        demographicsAnswer
      );
      // Check for demographic API failure
      if (!demographicResponse || demographicResponse.error) {
        alert("Demographics upload failed");
        return false;
      }

      // submit demographic image
      const binaryLinkUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/binary`;
      if (answerData?.demographicsPhoto?.value) {
        const file = answerData?.demographicsPhoto?.value;
        const fileName = file?.name;
        const fileType = file?.type;

        const imageResponse = await callAthenaPostApi(binaryLinkUrl, {
          contentType: fileType,
          fileName: fileName,
        });
        // Check for image upload URL failure
        if (!imageResponse || imageResponse.error) {
          alert("Failed to get image upload URL");
          return false;
        }

        const { uploadUrl } = imageResponse?.data?.HBx;
        const { fileUrl } = imageResponse?.data?.HBx;
        const formData = new FormData();
        formData.append("file", file);
        await axios.put(uploadUrl, file);

        // delaying 300 ms for complete upload of image on s3 server
        await new Promise((resolve) => setTimeout(resolve, 300));

        const postUploadUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/patient/driverslicense`;
        const fileResponse = await callAthenaPostApi(postUploadUrl, {
          fileName: fileName,
          contentType: fileType,
          HBxUrl: fileUrl,
        });
        // Check for final image API failure
        if (!fileResponse || fileResponse.error) {
          alert("Image metadata submission failed");
          return false;
        }
      }
      return true;
    } catch (error) {
      // Catch any other unexpected errors
      alert("Error during demographics upload:", error);
      return false;
    }
  }

  async function uploadAdditionalInfo() {
    const additionalInfo = {
      sexualOrientation: answerData?.sexualOrientation?.value || "",
      sexualOrientationOther: "",
      genderIdentity: answerData?.genderIdentity?.value || "",
      genderOther: "",
      sexAtBirth: answerData?.sexAtBirth?.value || "",
      pronouns: answerData?.pronouns?.value || "",
      homebound: answerData?.homebound?.value === "yes" ? "true" : "false",
      referralSourceId: answerData?.referralSourceId?.value || "",
      referralSourceOther: "",
    };
    const API_URL = process.env.REACT_APP_SCHEDULER_URL;
    const uploadAdditionalInfoUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/patient`;
    try {
      const response = await callAthenaPutApi(
        uploadAdditionalInfoUrl,
        additionalInfo
      );
      // Check for API failure
      if (!response || response.error) {
        alert("Additional info upload failed");
        return false;
      }
      if (
        journeyId === "6710106ced976ad58926ecb1" ||
        journeyId === "67101074ed976ad58926ecb2"
      ) {
        return uploadInsurance();
      }

      // If successful, return true
      return true;
    } catch (error) {
      // Catch any unexpected errors
      alert("Error during additional info upload:", error);
      return false;
    }
  }
  function dateFormatter(date) {
    const formattedDate = moment(date?.$d).format("MM/DD/YYYY");
    return formattedDate;
  }
  async function uploadInsurance() {
    const API_URL = process.env.REACT_APP_SCHEDULER_URL;
    const uploadInsuranceUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/patient/coverage`;
    const appointmentCoverageUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/appointment/coverage`;

    if (answerData?.areYouUsingYourInsuranceForTheVisit?.value === "yes") {
      //Insurance
      const insPlans = [
        {
          insurancePackageId: "0",
          name: "*SELF PAY*",
          ranking: "1",
          percentage: "65.36458333333333333333333333333333333333",
          address: {},
        },
        {
          insurancePackageId: "31724",
          name: "HUMANA (PPO)",
          productTypeName: "PPO",
          ranking: "2",
          percentage: "11.1328125",
          phone: "(800) 558-4444",
          address: {
            streetaddress: "PO BOX 14601",
            city: "LEXINGTON",
            state: "KY",
            ZIP: "40512-4601",
          },
        },
        {
          insurancePackageId: "20995",
          name: "HUMANA - GOLD PLUS (MEDICARE REPLACEMENT HMO)",
          productTypeName: "Medicare HMO",
          ranking: "3",
          percentage: "2.47395833333333333333333333333333333333",
          phone: "(800) 448-6262",
          address: {
            streetaddress: "PO BOX 14601",
            city: "LEXINGTON",
            state: "KY",
            ZIP: "40512-4601",
          },
        },
        {
          insurancePackageId: "1352",
          name: "AETNA",
          productTypeName: "OTHER",
          ranking: "4",
          percentage: "1.82291666666666666666666666666666666667",
          phone: "(888) 632-3862",
          address: {
            streetaddress: "PO BOX 981106",
            city: "EL PASO",
            state: "TX",
            ZIP: "79998-1106",
          },
        },
        {
          insurancePackageId: "-500",
          name: "PAYMENT PLAN",
          ranking: "5",
          percentage: "1.43229166666666666666666666666666666667",
          address: {},
        },
        {
          insurancePackageId: "8477",
          name: "BCBS-MA: BCBS (PPO)",
          productTypeName: "PPO",
          ranking: "6",
          percentage: ".9114583333333333333333333333333333333333",
          phone: "(800) 882-2060",
          address: {
            streetaddress: "PO BOX 986020",
            city: "BOSTON",
            state: "MA",
            ZIP: "02298-6020",
          },
        },
        {
          insurancePackageId: "92125",
          name: "AETNA - OLYMPUS HEALTHCARE (PPO)",
          productTypeName: "PPO",
          ranking: "7",
          percentage: ".5859375",
          phone: "(866) 936-1225",
          address: {
            streetaddress: "PO BOX 30259",
            city: "TAMPA",
            state: "FL",
            ZIP: "33630-3259",
          },
        },
        {
          insurancePackageId: "569524",
          name: "BCBS-RI (MEDICARE REPLACEMENT/ADVANTAGE - PPO)",
          productTypeName: "Medicare PPO",
          ranking: "7",
          percentage: ".5859375",
          phone: "(800) 230-9050",
          address: {
            streetaddress: "500 EXCHANGE ST",
            city: "PROVIDENCE",
            state: "RI",
            ZIP: "02903-2630",
          },
        },
      ];

      const insurancePackageId = answerData?.insuranceCompanyName?.value;
      const selectedPlan = insPlans.find(
        (plan) => plan.insurancePackageId === insurancePackageId
      );
      const insuranceProductTypeName = selectedPlan?.productTypeName || "";
      const insuranceName = selectedPlan?.name || "";

      const insuranceData = {
        insurancePackageId: insurancePackageId
          ? parseInt(insurancePackageId, 10)
          : "",
        insuranceProductTypeName: insuranceProductTypeName,
        insuranceName: insuranceName,
        policyNumber: answerData?.policyNumber?.value || "",
        groupID: answerData?.policyGroupId?.value || "",
        // sequenceNumber: 1,
        relationshipToInsured:
          answerData?.patientsRelationshipToInsuranceHolder?.value || "",
        policyHolder: {
          firstName: answerData?.policyHolderFirstName?.value || "",
          middleName: answerData?.policyHolderMiddleName?.value || "",
          lastName: answerData?.policyHolderLastName?.value || "",
          sex: answerData?.policyHolderGender?.value || "",
          DOB: answerData?.policyHolderDOB?.value
            ? dateFormatter(answerData?.policyHolderDOB?.value)
            : "",
          address: {
            streetAddress: answerData?.policyHolderAddress?.value || "",
            ZIP: parseInt(answerData?.policyHolderZipcode?.value, 10) || "",
            city: answerData?.policyHolderCity?.value || "",
            state: answerData?.policyHolderState?.value || "",
            country: answerData?.policyHolderCountry?.value || "",
          },
        },
      };

      insuranceData["sequenceNumber"] = 1;
      try {
        const coverageResponse = await callAthenaGetApi(uploadInsuranceUrl);

        if (!coverageResponse || coverageResponse.error) {
          alert("get coverage failed");
          return false;
        }

        const HBxData = coverageResponse.data?.HBx;
        insuranceData["sequenceNumber"] = HBxData?.length
          ? HBxData?.length + 1
          : 1;

        const insuranceResponse = await callAthenaPostApi(
          uploadInsuranceUrl,
          insuranceData
        );

        if (!insuranceResponse || insuranceResponse.error) {
          alert("Insurance upload failed");
          return false;
        }

        const { HBx } = insuranceResponse.data;
        const insuranceId = HBx?.id;

        const appointmentCoverageResponse = await callAthenaPutApi(
          appointmentCoverageUrl,
          {
            primaryCoverageId: insuranceId,
          }
        );

        if (!appointmentCoverageResponse || appointmentCoverageResponse.error) {
          alert("Appointment coverage update failed");
          return false;
        }
        const handleImageUpload = async () => {
          if (answerData?.snapAPhotographOfYourInsuranceCard?.value) {
            const file1 = answerData?.snapAPhotographOfYourInsuranceCard?.value;

            const file2 =
              answerData?.snapAPhotographOfYourInsuranceCardBackSide?.value;

            try {
              // Create a canvas to combine the two images
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");

              // Load both images as Image objects
              const img1 = new Image();
              const img2 = new Image();

              img1.src = URL.createObjectURL(file1);
              img2.src = URL.createObjectURL(file2);

              const loadImages = () =>
                new Promise((resolve, reject) => {
                  let loadedCount = 0;

                  const onLoad = () => {
                    loadedCount += 1;
                    if (loadedCount === 2) resolve();
                  };

                  const onError = (error) => reject(error);

                  img1.onload = onLoad;
                  img2.onload = onLoad;
                  img1.onerror = onError;
                  img2.onerror = onError;
                });

              // Wait for both images to load
              await loadImages();

              // Set canvas size to combine both images (vertically stacked in this example)
              canvas.width = Math.max(img1.width, img2.width);
              canvas.height = img1.height + img2.height;

              // Draw both images on the canvas
              ctx.drawImage(img1, 0, 0);
              ctx.drawImage(img2, 0, img1.height);

              // Convert the combined image to binary (Blob)
              const combinedImageBlob = await new Promise((resolve) => {
                canvas.toBlob((blob) => resolve(blob), "image/jpeg");
              });

              const binaryLinkUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/binary`;

              const res = await callAthenaPostApi(binaryLinkUrl, {
                contentType: combinedImageBlob?.type,
                fileName: "combined-insurance.jpg",
              });

              const { uploadUrl, fileUrl } = res?.data?.HBx;

              const postUploadUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/patient/coverage/image`;
              try {
                await axios.put(uploadUrl, combinedImageBlob);

                // delaying 300 ms for complete upload of image on s3 server
                await new Promise((resolve) => setTimeout(resolve, 300));

                await callAthenaPostApi(postUploadUrl, {
                  fileName: "combined-insurance.jpg",
                  coverageId: insuranceId,
                  HBxUrl: fileUrl,
                });
              } catch (error) {
                alert(`Error uploading combined-insurance.jpg:`, error);
                return false;
              }
            } catch (error) {
              console.error("Error combining and uploading files:", error);
            }
          }
        };

        await handleImageUpload();

        return true; // Successful completion
      } catch (error) {
        alert("Error during get coverage fetch:", error);
        return false;
      }
    } else {
      const insurancePackageId = "0";
      const selectedPlan = {
        insurancePackageId: "0",
        name: "*SELF PAY*",
        ranking: "1",
        percentage: "65.36458333333333333333333333333333333333",
        address: {},
      };
      const insuranceProductTypeName = selectedPlan?.productTypeName || "";
      const insuranceName = selectedPlan?.name || "";

      const insuranceData = {
        insurancePackageId: insurancePackageId
          ? parseInt(insurancePackageId, 10)
          : "",
        insuranceProductTypeName: insuranceProductTypeName,
        insuranceName: insuranceName,
        policyNumber: answerData?.policyNumber?.value || "",
        groupID: answerData?.policyGroupId?.value || "",
        // sequenceNumber: 1,
        relationshipToInsured:
          answerData?.patientsRelationshipToInsuranceHolder?.value || "",
        policyHolder: {
          firstName: answerData?.policyHolderFirstName?.value || "",
          middleName: answerData?.policyHolderMiddleName?.value || "",
          lastName: answerData?.policyHolderLastName?.value || "",
          sex: answerData?.policyHolderGender?.value || "",
          address: {
            streetAddress: answerData?.policyHolderAddress?.value || "",
            ZIP: parseInt(answerData?.policyHolderZipcode?.value, 10) || "",
            city: answerData?.policyHolderCity?.value || "",
            state: answerData?.policyHolderState?.value || "",
            country: answerData?.policyHolderCountry?.value || "",
          },
        },
      };
      try {
        const insuranceResponse = await callAthenaPostApi(
          uploadInsuranceUrl,
          insuranceData
        );

        if (!insuranceResponse || insuranceResponse.error) {
          alert("Insurance upload failed");
          return false;
        }

        const { HBx } = insuranceResponse.data;
        const insuranceId = HBx?.id;

        const appointmentCoverageResponse = await callAthenaPutApi(
          appointmentCoverageUrl,
          {
            primaryCoverageId: insuranceId,
          }
        );

        if (!appointmentCoverageResponse || appointmentCoverageResponse.error) {
          alert("Appointment coverage update failed");
          return false;
        }

        return true; // Successful completion
      } catch (error) {
        alert("Error during insurance upload:", error);
        return false;
      }
    }
  }

  async function uploadPharmacy() {
    const pharmacyId =
      parseInt(answerData?.pharmacyProviders?.value?.clinicalProviderId, 10) ||
      "";
    const API_URL = process.env.REACT_APP_SCHEDULER_URL;
    const uploadPharmacyUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/patient/pharmacy`;

    try {
      const response = await callAthenaPutApi(uploadPharmacyUrl, {
        preferredPharmacy: pharmacyId,
      });

      if (!response || response.error) {
        alert("Pharmacy upload failed");
        return false;
      }

      return true; // Successful completion
    } catch (error) {
      alert("Error during pharmacy upload:", error);
      return false;
    }
  }

  async function uploadCareTeam() {
    const primaryCareProviderId =
      parseInt(
        answerData?.primaryCarePhysician?.value?.clinicalProviderId,
        10
      ) || "";
    const referringProviderId =
      parseInt(answerData?.referredPhysician?.value?.clinicalProviderId, 10) ||
      "";

    const API_URL = process.env.REACT_APP_SCHEDULER_URL;
    const primaryCareProviderUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/patient/careteam/primaryprovider`;
    const referringCareProviderUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/patient/careteam/referringprovider`;

    try {
      // Upload Primary Care Provider
      const primaryResponse = await callAthenaPutApi(primaryCareProviderUrl, {
        clinicalProviderId: primaryCareProviderId,
      });

      if (!primaryResponse || primaryResponse.error) {
        alert("Primary Care Provider upload failed");
        return false;
      }

      // Upload Referring Provider
      const referringResponse = await callAthenaPutApi(
        referringCareProviderUrl,
        {
          clinicalProviderId: referringProviderId,
        }
      );

      if (!referringResponse || referringResponse.error) {
        alert("Referring Provider upload failed");
        return false;
      }

      return true; // Successful completion
    } catch (error) {
      alert("Error during care team upload:", error);
      return false;
    }
  }

  async function uploadMedicalCondition() {
    const dateFormatter = (dateString) => {
      if (dateString) {
        const date = new Date(dateString);

        const formattedDate =
          (date.getMonth() + 1).toString().padStart(2, "0") +
          "/" +
          date.getDate().toString().padStart(2, "0") +
          "/" +
          date.getFullYear();
        return formattedDate;
      }
      return dateString;
    };

    const API_URL = process.env.REACT_APP_SCHEDULER_URL;
    const API_URL_PDF = process.env.REACT_APP_PDF_RENDERER_URL;
    const allergyHistoryUploadUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/questionnaire/healthhistory/5792`;
    const medicationHistoryUploadUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/questionnaire/healthhistory/5793`;
    const pdfUploadUrl = `${API_URL_PDF}/api/v1/pdf/${hashIdFromQP}/render`;
    const putMedicalHistoryUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/questionnaire/medicalhistory`;

    const medicalConditions =
      answerData?.pleaseEnterYourMedicalConditionSBelow?.value || [];
    const medicalData = medicalConditions.map((item) => ({
      questionid: item,
      answer: "Y",
    }));

    const medicalResponse = await callAthenaPutApi(putMedicalHistoryUrl, {
      data: medicalData,
    });

    if (!medicalResponse || medicalResponse.error) {
      alert("Medical conditions upload failed");
      return false;
    }

    if (allergyData) {
      const groupedData = allergyData.reduce((acc, curr) => {
        curr.components.forEach((component) => {
          if (component.type === "searchAllergy") {
            const allergyId = component.selectedOption;
            let existingEntry = acc.find(
              (entry) => entry.allergyid === allergyId
            );

            if (!existingEntry) {
              existingEntry = {
                allergyid: allergyId,
                onsetdate: "",
                allergyreactionid: [],
                severityid: [],
              };
              acc.push(existingEntry);
            }

            // Find the corresponding onset date, reactions, and severity for this allergy
            curr.components.forEach((subComponent) => {
              if (subComponent.subKey === "onsetdate") {
                existingEntry.onsetdate = subComponent.selectedOption;
              }
              if (subComponent.subKey === "allergyreactionid") {
                existingEntry.allergyreactionid.push(
                  subComponent.selectedOption
                );
              }
              if (subComponent.subKey === "severityid") {
                existingEntry.severityid.push(subComponent.selectedOption);
              }
            });
          }
        });
        return acc;
      }, []);

      // Format the final output
      const allergyOutput = {
        questions: groupedData.map((item) => ({
          allergyid: item.allergyid.toString(),
          onsetdate: dateFormatter(item.onsetdate),
          allergyreactionid: item.allergyreactionid, // keep all reactions in an array
          severityid: item.severityid, // keep all severities in an array
        })),
      };

      try {
        const allergyHistoryUploadResponse = await callAthenaPutApi(
          allergyHistoryUploadUrl,
          {
            data: [
              {
                portalformsectionid: 7556,
                complete: true,
                ...allergyOutput,
              },
            ],
          }
        );

        if (
          !allergyHistoryUploadResponse ||
          allergyHistoryUploadResponse.error
        ) {
          alert("Allergy history upload failed");
          return false;
        }
      } catch (error) {
        alert("Error during Allergy history upload:", error);
        return false;
      }
    }

    if (medicationData) {
      const result = medicationData.map((item) => {
        const components = item.components;

        const medication = components.find(
          (comp) => comp.subKey === "searchMedication"
        );
        const startDate = components.find(
          (comp) => comp.subKey === "startdate"
        );
        const stopDate = components.find((comp) => comp.subKey === "stopdate");
        const reasonForDiscontinuation = components.find(
          (comp) => comp.subKey === "meddeactivatereasonid"
        );
        const frequency = components.find(
          (comp) => comp.subKey === "frequency"
        );
        const dosagequantity = components.find(
          (comp) => comp.subKey === "dosagequantity"
        );
        return {
          medicationid: medication?.selectedOption,
          startdate: dateFormatter(startDate?.selectedOption),
          stopdate: dateFormatter(stopDate?.selectedOption),
          meddeactivatereasonid: reasonForDiscontinuation?.selectedOption,
          frequency: frequency?.selectedOption,
          displaydosageunits: "mg", //"tablet(s)",
          dosagequantity: dosagequantity?.selectedOption,
        };
      });

      try {
        const medicationHistoryUploadResponse = await callAthenaPutApi(
          medicationHistoryUploadUrl,
          {
            data: [
              {
                portalformsectionid: 7557,
                complete: true,
                questions: result,
              },
            ],
          }
        );

        if (
          !medicationHistoryUploadResponse ||
          medicationHistoryUploadResponse.error
        ) {
          alert("Medication history upload failed");
          return false;
        }
      } catch (error) {
        alert("Error during Medication history:", error);
        return false;
      }
    }

    if (qnaList) {
      try {
        const pdfUploadResponse = axios.post(pdfUploadUrl, {
          type: "clinical",
          templateId: "67319cf4c7ae5f8a5051ab39",
          title: "Health History",
          internalNote: "Clinical",
          qnaList: qnaList,
        });

        if (!pdfUploadResponse || pdfUploadResponse.error) {
          alert("PDF upload failed");
          return false;
        }
      } catch (error) {
        alert("Error during PDF upload:", error);
        return false;
      }
    }

    return true;
  }

  async function uploadSocialCondition() {
    const API_URL = process.env.REACT_APP_SCHEDULER_URL;
    const API_URL_PDF = process.env.REACT_APP_PDF_RENDERER_URL;
    const pdfUploadUrl = `${API_URL_PDF}/api/v1/pdf/${hashIdFromQP}/render`;
    const getSocialHistoryUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/questionnaire/socialhistory`;

    const socialData = [];

    socialKeys.forEach((key) => {
      if (answerData?.[key]?.value) {
        // Check if the key exists and has a value
        socialData.push({
          key: key,
          answer: answerData[key].value,
        });
      }
    });

    const response = await callAthenaPutApi(getSocialHistoryUrl, {
      data: socialData,
    });

    // Check for response validity
    if (!response || response.error) {
      alert("Social history upload failed");
      return false;
    }

    if (qnaList) {
      try {
        const pdfUploadResponse = axios.post(pdfUploadUrl, {
          type: "clinical",
          templateId: "67319cf4c7ae5f8a5051ab39",
          title: "Social History",
          internalNote: "Clinical",
          qnaList: qnaList,
        });

        if (!pdfUploadResponse || pdfUploadResponse.error) {
          alert("PDF upload failed");
          return false;
        }
      } catch (error) {
        alert("Error during PDF upload:", error);
        return false;
      }
    }
    return true;
  }

  async function uploadPrivacy() {
    try {
      // Upload privacy information
      const signatureName = answerData?.patientGuardianSignature?.value || "";
      const dateTime =
        formatTimestamp(answerData?.patientGuardianSignature?.time) || "";

      const API_URL = process.env.REACT_APP_SCHEDULER_URL;
      const postPrivacyNoticeUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/patient/consent/privacynotice`;
      const postRealeaseOfBillingInformationUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/patient/consent/realeaseofbillinginformation`;
      const postAssignmentOfBenefitsUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/patient/consent/assignmentofbenefits`;

      const privacyTypes = [
        {
          type: "Privacy Notice",
          expirationDate: null,
          apiUrl: postPrivacyNoticeUrl,
        },
        {
          type: "Release of Billing Information",
          expirationDate: addOneYearToDate(dateTime),
          apiUrl: postRealeaseOfBillingInformationUrl,
        },
        {
          type: "Assignment of Benefits",
          expirationDate: addOneYearToDate(dateTime),
          apiUrl: postAssignmentOfBenefitsUrl,
        },
      ];

      for (const privacy of privacyTypes) {
        const response = await callAthenaPostApi(privacy?.apiUrl, {
          signatureName: signatureName,
        });

        // Check for response validity
        if (!response || response.error) {
          alert(`${privacy?.type} upload failed`);
          return false;
        }
      }
      const documentConsentUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/document/consent`;
      const documentClinicalUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/document/clinical`;

      // Submit privacy document
      // await submitDocument(
      //   "dummyPrivacy.pdf",
      //   "(DUMMY PRIVACY PDF)",
      //   "ADMIN_CONSENT",
      //   documentConsentUrl
      // );
      // await submitDocument(
      //   "dummyClinical.pdf",
      //   "(DUMMY CLINICAL PDF)",
      //   "CLINICALDOCUMENT",
      //   documentClinicalUrl
      // );

      // const appointmentResponse = await callAthenaApi(journeyId, {
      //   resource: "AppointmentCheckin",
      //   responseSystem: "HBx",
      //   requestSystem: "HBx",
      //   method: "POST",
      //   content: {
      //     patientId: patientId,
      //     appointmentId: appointmentId,
      //   },
      // });

      // Check for response validity
      // if (!appointmentResponse || appointmentResponse.error) {
      //   alert("Appointment check-in failed");
      //   return false;
      // }

      const API_URL_PDF = process.env.REACT_APP_PDF_RENDERER_URL;
      const pdfUploadUrl = `${API_URL_PDF}/api/v1/pdf/${hashIdFromQP}/render`;
      const pdfUploadResponseForPrivacy = axios.post(pdfUploadUrl, {
        type: "consent",
        templateId: "673722b5335fad4986ecc676",
        title: "Patient Consent",
        internalNote: "Patient Consent",
        userSignature: signatureName,
      });
      const pdfUploadResponseForHippa = axios.post(pdfUploadUrl, {
        type: "consent",
        templateId: "673722cd335fad4986ecc677",
        title: "Hippa Notice",
        internalNote: "Hippa Notice",
        userSignature: signatureName,
      });

      if (!pdfUploadResponseForHippa || pdfUploadResponseForHippa.error) {
        alert("Hippa PDF upload failed");
        return false;
      }
      if (!pdfUploadResponseForPrivacy || pdfUploadResponseForPrivacy.error) {
        alert("Privacy PDF upload failed");
        return false;
      }

      return true; // Successful completion
    } catch (error) {
      alert("Error during privacy upload:", error);
      return false;
    }
  }

  async function submitDocument(
    fileName,
    contentText,
    documentType,
    documentTypeUploadUrl
  ) {
    const pdfContent = [
      "%PDF-1.4",
      "1 0 obj",
      "<< /Type /Catalog /Pages 2 0 R >>",
      "endobj",
      "2 0 obj",
      "<< /Type /Pages /Kids [ 3 0 R ] /Count 1 >>",
      "endobj",
      "3 0 obj",
      "<< /Type /Page /Parent 2 0 R /MediaBox [ 0 0 612 792 ] /Contents 4 0 R >>",
      "endobj",
      "4 0 obj",
      "<< /Length 44 >>",
      "stream",
      "BT",
      "/F1 24 Tf",
      "100 700 Td",
      `${contentText} Tj`,
      "ET",
      "endstream",
      "endobj",
      "xref",
      "0 5",
      "0000000000 65535 f",
      "0000000009 00000 n",
      "0000000090 00000 n",
      "0000000153 00000 n",
      "0000000198 00000 n",
      "trailer",
      "<< /Size 5 /Root 1 0 R >>",
      "startxref",
      "252",
      "%%EOF",
    ].join("\n");

    const API_URL = process.env.REACT_APP_SCHEDULER_URL;
    const binaryLinkUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/binary`;

    const pdfBlob = new Blob([pdfContent], { type: "application/pdf" });
    const formData = new FormData();
    formData.append("file", pdfBlob, fileName);

    try {
      const res = await callAthenaPostApi(binaryLinkUrl, {
        contentType: "pdf",
        fileName: fileName,
      });

      const { uploadUrl, fileUrl } = res?.data?.HBx;

      await axios.put(uploadUrl, pdfBlob);

      // delaying 300 ms for complete upload of image on s3 server
      await new Promise((resolve) => setTimeout(resolve, 300));

      await callAthenaPostApi(documentTypeUploadUrl, {
        contentType: "PDF",
        internalNote: documentType === "ADMIN_CONSENT" ? "Hipaa" : "Clinical",
        fileName: fileName,
        HBxUrl: fileUrl,
      });
    } catch (error) {
      alert(`Error uploading ${fileName}:`, error);
      return false;
    }
  }

  async function uploadPaymentDetails() {
    const iDontWantToAddMyCardOnFile = answerData?.[
      "iDontWantToAddMyCardOnFile"
    ]?.value?.includes("iDontWantToAddMyCardOnFile");
    const UseYourCardOnFile =
      answerData?.selectHowSynaptrxSleepCanUseYourCardOnFile?.value || "";
    if (!iDontWantToAddMyCardOnFile) {
      const paymentType = UseYourCardOnFile;
      try {
        // Upload Payment deatils
        const content = {
          email: answerData?.paymentEmail?.value || "",
          maxAmount: Math.min(
            parseInt(answerData?.paymentAmount?.value || "", 10),
            1500
          ),
          nameOnCard: answerData?.nameOnCard?.value || "",
          cardNumber: answerData?.cardNumber?.value || "",
          cvv: parseInt(answerData?.cvv?.value || "", 10),
          address: {
            streetAddress: answerData?.paymentAddress?.value || "",
            ZIP: parseInt(answerData?.paymentZipCode?.value || "", 10),
            city: answerData?.paymentCity?.value || "",
            state: answerData?.paymentState?.value || "",
            country: answerData?.paymentCountry?.value || "",
          },
          expirationDate: answerData?.expirationDate?.value || "",
        };

        const API_URL = process.env.REACT_APP_SCHEDULER_URL;
        const paymentUploadUrl =
          paymentType === "singleAppointment"
            ? `${API_URL}/api/v1/checkin/${hashIdFromQP}/appointment/contract/singleappointment`
            : `${API_URL}/api/v1/checkin/${hashIdFromQP}/appointment/contract/oneyear`;
        const paymentResponse = await callAthenaPostApi(
          paymentUploadUrl,
          content
        );

        if (!paymentResponse || paymentResponse.error) {
          alert("Payment details upload failed");
          return false;
        }

        return true; // Successful completion
      } catch (error) {
        alert("Error during Payment details upload:", error);
        return false;
      }
    } else return true;
  }

  async function callAthenaAPIs() {
    const API_URL = process.env.REACT_APP_SCHEDULER_URL;
    const getGenderUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/configuration/genderIdentity`;
    const getReferralSourceUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/configuration/referralsources`;
    const getInsuranceUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/insuranceplan`;
    const getCoverageUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/patient/coverage`;
    const getMedicalHistoryUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/questionnaire/medicalhistory`;
    const getSocialHistoryUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/questionnaire/socialhistory`;
    const getPrivacyInfoUrl = `${API_URL}/api/v1/checkin/${hashIdFromQP}/patient/consent`;
    try {
      // Health history
      if (formData?.formId === "66e947e15b3a1419fb71449f") {
        await Promise.all([callAthenaGetApi(getMedicalHistoryUrl)]);
      }

      // Social history
      if (formData?.formId === "66e919bd5b3a1419fb7132e5") {
        await Promise.all([callAthenaGetApi(getSocialHistoryUrl)]);
      }

      // Privacy info
      if (formData?.formId === "66e91d3e5b3a1419fb7132fa") {
        await callAthenaGetApi(getPrivacyInfoUrl);
      }
    } catch (error) {
      alert("Error during Athena API calls:", error);
    }
  }

  const fetchFormSchema = async (activeComponent) => {
    setFormSchema(activeComponent?.form?.form_schema?.formData);
    // setActiveFormPage(activeComponent?.form?.form_schema?.formData[0].content);
    // setActiveFormPageId(activeComponent?.form?.form_schema?.formData[0].id);
    setFormAnswer(activeComponent?.form);
  };

  const deepCloneWithFiles = (obj) => {
    return structuredClone(obj);
  };

  // Function to format date and time as 'dd/MM/yyyy HH:mm:ss'
  const formatTimestamp = (dateInput) => {
    const date = new Date(dateInput);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
  };

  function addOneYearToDate(dateInput) {
    const date = new Date(dateInput);

    // Add one year to the date
    date.setFullYear(date.getFullYear() + 1);

    const newDay = String(date.getDate()).padStart(2, "0");
    const newMonth = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const newYear = date.getFullYear();

    return `${newMonth}/${newDay}/${newYear}`;
  }

  const setAnswer = async (question, answer) => {
    // let tempAnswerList = JSON.parse(JSON.stringify(formattedAnswer?.answers));
    let tempAnswerList = deepCloneWithFiles(formattedAnswer?.answers);
    let tempQnaList = deepCloneWithFiles(qnaList);
    const questionKey = question?.key;
    if (questionKey === "HBxFirstName") {
      setHBxFirstName(answer?.value);
    }
    if (questionKey === "HBxReasonForVisit") {
      setHBxReasonForVisit(answer?.value);
    }
    if (questionKey === "HBxLastName") {
      setHBxLastName(answer?.value);
    }
    if (questionKey === "HBxEmail") {
      setHBxEmail(answer?.value);
    }
    if (questionKey === "HBxDob") {
      setHBxDob(answer?.value);
    }

    if (questionKey === "preferredTimezoneForAppointmentBooking") {
      setPatientTZ(timezoneMapper[answer?.value]);
    }

    let index = tempAnswerList.findIndex((item) => item.key === questionKey);
    if (index === -1) {
      tempAnswerList.push({
        key: questionKey,
        question: question?.label,
        answer,
      });
    } else {
      tempAnswerList[index].answer = answer;
    }
    setFormattedAnswer((prev) => ({ ...prev, answers: tempAnswerList }));
    let indexForQna = tempQnaList.findIndex((item) => item.key === questionKey);
    const formatAnswer = (type, originalAnswer) => {
      if (type === "selectboxes" && Array.isArray(originalAnswer?.label)) {
        let formattedStringAnswer = originalAnswer?.label.join(", ");
        return formattedStringAnswer;
      }
      if (
        type === "searchAllergy" ||
        type === "DROPDOWN" ||
        type === "searchMedication" ||
        type === "radio" ||
        type === "select"
      ) {
        return originalAnswer?.label;
      }
      if (type === "datetime") {
        let formattedStringAnswer =
          originalAnswer?.value?.$d?.toLocaleDateString("en-US");
        return formattedStringAnswer;
      }
      return originalAnswer?.value;
    };
    let formattedStringAnswer = formatAnswer(question?.type, answer);
    if (indexForQna === -1) {
      tempQnaList.push({
        key: questionKey,
        question: question?.label,
        answer: formattedStringAnswer,
      });
    } else {
      tempQnaList[indexForQna].answer = formattedStringAnswer;
    }
    setQnaList(tempQnaList);

    // let tempFormAnswer = JSON.parse(JSON.stringify(formAnswer));
    let tempFormAnswer = deepCloneWithFiles(formAnswer);
    tempFormAnswer?.form_schema?.formData?.map((formPage) => {
      // formPage?.contents && formPage?.content?.components && formPage?.content?.components.map((item)=>{
      formPage?.content?.components.map((item) => {
        if (item?.key === questionKey) {
          item.answer = answer;
          if (answer && answer?.value) item.formValidation = true;
          else item.formValidation = false;
        }
      });
    });
    setFormAnswer(tempFormAnswer);
    setFormSchema(tempFormAnswer?.form_schema?.formData);

    for (let i = 0; i < tempFormAnswer?.form_schema?.formData?.length; i++) {
      if (tempFormAnswer?.form_schema?.formData[i].id === activeFormPageId) {
        setActiveFormPage(tempFormAnswer?.form_schema?.formData[i]?.content);
      }
    }

    // let updatedAnswerData = JSON.parse(JSON.stringify(answerData));
    let updatedAnswerData = deepCloneWithFiles(answerData);
    console.log(updatedAnswerData);
    updatedAnswerData[questionKey] = answer;
    setAnswerData(updatedAnswerData);
    // console.log(tempFormAnswer);

    let updatedJourneyAnswers = JSON.parse(
      JSON.stringify(completeJourneyAnswers)
    );
    let indexJourneyAnswer = updatedJourneyAnswers.findIndex(
      (item) => item.key === questionKey
    );
    if (indexJourneyAnswer === -1) {
      updatedJourneyAnswers.push({
        key: questionKey,
        question: question?.label,
        answer,
      });
    } else {
      updatedJourneyAnswers[indexJourneyAnswer].answer = answer;
    }
    setCompleteJourneyAnswers(updatedJourneyAnswers);

    // let updatedJourneyData = JSON.parse(JSON.stringify(journeyData));
    let updatedJourneyData = deepCloneWithFiles(journeyData);

    let screens = updatedJourneyData?.journey_schema?.screens;

    for (let i = 0; i < screens?.length; i++) {
      if (screens[i]?.id === activeScreenID) {
        let screenPages = screens[i]?.screenPages;
        for (let j = 0; j < screenPages?.length; j++) {
          if (screenPages[j]?.id === activeScreenPageID) {
            let form = screenPages[j]?.components?.form?.form_schema?.formData;
            for (let idx = 0; idx < form?.length; idx++) {
              if (form[idx]?.id === activeFormPageId) {
                let formContent = form[idx]?.content?.components;
                formContent?.map((ques) => {
                  if (ques?.key === questionKey) ques.answer = answer;
                  if (answer && answer?.value) ques.formValidation = true;
                  else ques.formValidation = false;
                });
              }
            }
          }
        }
      }
    }
    setJourneyData(updatedJourneyData);

    const iDontWantToAddMyCardOnFile = updatedAnswerData?.[
      "iDontWantToAddMyCardOnFile"
    ]?.value?.includes("iDontWantToAddMyCardOnFile");
    if (
      !iDontWantToAddMyCardOnFile &&
      questionKey === "selectHowSynaptrxSleepCanUseYourCardOnFile"
    ) {
      const UseYourCardOnFile =
        updatedAnswerData?.selectHowSynaptrxSleepCanUseYourCardOnFile?.value ||
        "";
      if (UseYourCardOnFile) {
        const API_URL = process.env.REACT_APP_SCHEDULER_URL;
        const paymentUploadUrl =
          UseYourCardOnFile === "singleAppointment"
            ? `${API_URL}/api/v1/checkin/${hashIdFromQP}/appointment/contract/singleappointment`
            : `${API_URL}/api/v1/checkin/${hashIdFromQP}/appointment/contract/oneyear`;
        await callAthenaGetApi(paymentUploadUrl);
      }
    }
  };

  const handleBack = () => {
    for (let i = 0; i < formSchema.length; i++) {
      if (formSchema[i].id === activeFormPageId) {
        if (i > 0) {
          setActiveFormPage(formSchema[i - 1].content);
          setActiveFormPageId(formSchema[i - 1].id);
        } else if (i === 0) {
          handleBackScreenPage();
        }
      }
    }
  };
  const handleNext = () => {
    let nextActionValid = true;
    for (let i = 0; i < formSchema.length; i++) {
      if (formSchema[i].id === activeFormPageId) {
        formSchema[i].content.components.map((questionData) => {
          if (
            checkForQuestionToShow(questionData) &&
            questionData?.validate?.required &&
            !questionData?.answer
          ) {
            nextActionValid = false;
            setFormValidation(false);
          }
        });
      }
    }
    if (formData?.formId === "66ff55385b3a1419fb71ad79") {
      if (answerData?.doYouHaveMedicare?.value === "yes") {
        nextActionValid = false;
        setOpenModal(true);
        setModalTitle("Redirecting you to");
        setModalSubtitle("Synaptrx");
        setTimeout(() => {
          window.open(
            "https://schedule.yosicare.com/d6164e9e8d11921b63143e31ad61e4b3",
            "_blank" // This specifies opening in a new tab
          );

          setOpenModal(false);
          setModalTitle("");
          setModalSubtitle("");
        }, 3000);
      }
    }

    if (formData?.formId === "66ff6e1e5b3a1419fb71b410") {
      if (answerData?.doYouHaveMedicare?.value === "yes") {
        nextActionValid = false;
        setOpenModal(true);
        setModalTitle("Redirecting you to");
        setModalSubtitle("Synaptrx");
        setTimeout(() => {
          window.open(
            "https://schedule.yosicare.com/d6164e9e8d11921b63143e31ad61e4b3",
            "_blank" // This specifies opening in a new tab
          );

          setOpenModal(false);
          setModalTitle("");
          setModalSubtitle("");
        }, 3000);
      } else if (answerData?.doYouHaveMedicare?.value === "no") {
        if (answerData?.areYouUsingInsuranceForThisVisit?.value === "yes") {
          nextActionValid = false;
          setOpenModal(true);
          setModalTitle("Redirecting you to");
          setModalSubtitle("Synaptrx");
          setTimeout(() => {
            window.open(
              "https://schedule.yosicare.com/d6164e9e8d11921b63143e31ad61e4b3",
              "_blank" // This specifies opening in a new tab
            );
            setOpenModal(false);
            setModalTitle("");
            setModalSubtitle("");
          }, 3000);
        }
      }
    }

    if (formData?.formId === "66ec5b455b3a1419fb717b99") {
      if (
        answerData?.iDontWantToAddMyCardOnFile &&
        answerData.iDontWantToAddMyCardOnFile?.value?.includes(
          "iDontWantToAddMyCardOnFile"
        )
      ) {
        nextActionValid = false;
        handleSubmit();
      }
    }

    if (nextActionValid) {
      for (let i = 0; i < formSchema.length; i++) {
        if (formSchema[i].id === activeFormPageId) {
          if (i < formSchema.length - 1) {
            setActiveFormPage(formSchema[i + 1].content);
            setActiveFormPageId(formSchema[i + 1].id);
            setFormValidation(true);
          }
        }
      }
    }
  };

  const handleSubmit = async () => {
    let nextActionValid = true;
    for (let i = 0; i < formSchema.length; i++) {
      if (formSchema[i].id === activeFormPageId) {
        formSchema[i].content.components.map((questionData) => {
          if (
            questionData?.validate?.required &&
            !(
              questionData?.answer &&
              questionData.answer?.value &&
              questionData.answer.value !== ""
            )
          ) {
            questionData.formValidation = false;
            nextActionValid = false;
            setFormValidation(false);
          }
        });
        setActiveFormPage(formSchema[i].content);
      }
    }
    // Handle API calls and set nextActionValid to false if any fail
    const apiCalls = [
      formData?.formId === "66e90d445b3a1419fb7131ce" && uploadDemographics(),
      formData?.formId === "66e916775b3a1419fb713231" && uploadAdditionalInfo(),
      formData?.formId === "66e918895b3a1419fb7132b7" && uploadInsurance(),
      formData?.formId === "66eaaec35b3a1419fb715735" && uploadPharmacy(),
      formData?.formId === "66e919305b3a1419fb7132de" && uploadCareTeam(),
      formData?.formId === "66e947e15b3a1419fb71449f" &&
        uploadMedicalCondition(),
      formData?.formId === "66e919bd5b3a1419fb7132e5" &&
        uploadSocialCondition(),
      formData?.formId === "66e91dbc5b3a1419fb713308" && uploadPrivacy(),
      formData?.formId === "66ec5b455b3a1419fb717b99" && uploadPaymentDetails(),
    ];

    for (let apiCall of apiCalls) {
      if (apiCall) {
        setShowLoader(true);
        const response = await apiCall;
        nextActionValid = response;
        if (response?.error) {
          nextActionValid = false; // Set to false if any API fails
          break; // Stop further API calls if one fails
        }
      }
      setShowLoader(false);
    }

    if (nextActionValid) {
      // console.log(formAnswer);
      // console.log(formattedAnswer);
      newFormSubmit(formattedAnswer, formData?.form?.org);
      handleNextScreenPage();
    }
  };

  const checkForQuestionToShow = (ques) => {
    let show = true;
    if (ques?.conditional && ques.conditional?.eq && ques.conditional?.when) {
      show = !ques.conditional?.show;
      let journeyAnswerIndex = completeJourneyAnswers.findIndex(
        (item) => item.key === ques.conditional.when
      );
      let answer = completeJourneyAnswers[journeyAnswerIndex]?.answer?.value;
      if (
        (typeof answer === "string" && answer === ques.conditional?.eq) ||
        (Array.isArray(answer) && answer.includes(ques.conditional?.eq))
      ) {
        show = ques.conditional?.show || false;
      }
    }
    return show;
  };

  useEffect(() => {
    if (formData) {
      setFormattedAnswer({
        type: formData?.type,
        formId: formData.formId,
        answers: [],
      });
      setQnaList([]);
      fetchFormSchema(formData);
      callAthenaAPIs();
    }
  }, [formData]);

  useEffect(() => {
    setActiveFormPage(activeFormPageData?.content);
    setActiveFormPageId(activeFormPageData?.id);
  }, [activeFormPageData]);

  const handleContainerKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default action on pressing Enter
    }
  };

  return (
    <div className="w-auto" onKeyDown={handleContainerKeyDown}>
      <div className="w-auto p-5">
        {showloader ? (
          <div className=" flex flex col min-h-96 justify-center items-center">
            <Loader />
          </div>
        ) : (
          <>
            {formData?.formId === insuranceForm && insuranceCollectedStatus ? (
              <div className="content-width">
                <div className="flex flex-col">
                  <div className="text-center font-semibold text-lg py-3 shadow my-5 px-24 rounded bg-gray-100">
                    Insurance Collected for this Checkin
                  </div>
                </div>
              </div>
            ) : formData?.formId === paymentForm && paymentCollectedStatus ? (
              <div className="content-width">
                <div className="flex flex-col">
                  <div className="text-center font-semibold text-lg py-3 shadow my-5 px-24 rounded bg-gray-100">
                    Payment Details Collected for this Checkin
                  </div>
                </div>
              </div>
            ) : (
              <>
                {activeFormPage &&
                  activeFormPage?.components &&
                  activeFormPage?.components?.map((item) => {
                    return (
                      <>
                        {item?.type === "textfield" &&
                          checkForQuestionToShow(item) && (
                            <div className="form-width mx-auto flex justify-center my-3">
                              <TextField
                                data={item}
                                setAnswer={setAnswer}
                                formValidation={formValid}
                                formClass={formClass}
                              />
                            </div>
                          )}
                        {item?.type === "americanPhoneField" &&
                          checkForQuestionToShow(item) && (
                            <div className="form-width mx-auto flex justify-center my-3">
                              <AmericanPhoneField
                                data={item}
                                setAnswer={setAnswer}
                                formValidation={formValid}
                                formClass={formClass}
                              />
                            </div>
                          )}

                        {item?.type === "textarea" &&
                          checkForQuestionToShow(item) && (
                            <div className="form-width mx-auto flex justify-center my-3">
                              <TextArea
                                data={item}
                                setAnswer={setAnswer}
                                formClass={formClass}
                                formValidation={formValid}
                              />
                            </div>
                          )}
                        {item?.type === "password" &&
                          checkForQuestionToShow(item) && (
                            <div className="form-width mx-auto flex justify-center my-3">
                              <Password
                                data={item}
                                setAnswer={setAnswer}
                                formClass={formClass}
                                formValidation={formValid}
                              />
                            </div>
                          )}
                        {item?.type === "datetime" &&
                          checkForQuestionToShow(item) && (
                            <div className="form-width mx-auto flex justify-center my-3">
                              <DateInput
                                data={item}
                                setAnswer={setAnswer}
                                formClass={formClass}
                                formValidation={formValid}
                              />
                            </div>
                          )}
                        {item?.type === "email" &&
                          checkForQuestionToShow(item) && (
                            <div className="form-width mx-auto flex justify-center my-3">
                              <Email
                                data={item}
                                setAnswer={setAnswer}
                                formClass={formClass}
                                formValidation={formValid}
                              />
                            </div>
                          )}
                        {item?.type === "checkbox" &&
                          checkForQuestionToShow(item) && (
                            <div className="form-width mx-auto flex justify-center my-3">
                              <SingleCheckBox
                                data={item}
                                setAnswer={setAnswer}
                                formClass={formClass}
                                formValidation={item?.formValidation}
                              />
                            </div>
                          )}
                        {item?.type === "select" &&
                          checkForQuestionToShow(item) && (
                            <div className="form-width mx-auto flex justify-center my-3">
                              <DropDownSelector
                                data={item}
                                setAnswer={setAnswer}
                                formClass={formClass}
                                formValidation={formValid}
                              />
                            </div>
                          )}
                        {item?.type === "number" &&
                          checkForQuestionToShow(item) && (
                            <div className="form-width mx-auto flex justify-center my-3">
                              <NumberInput
                                data={item}
                                setAnswer={setAnswer}
                                formClass={formClass}
                                formValidation={formValid}
                              />
                            </div>
                          )}
                        {item?.type === "phoneNumber" &&
                          checkForQuestionToShow(item) && (
                            <div className="form-width mx-auto flex justify-center my-3">
                              <Telephone
                                data={item}
                                setAnswer={setAnswer}
                                formClass={formClass}
                                formValidation={formValid}
                              />
                            </div>
                          )}
                        {item?.type === "dropDownWithSearch" &&
                          checkForQuestionToShow(item) && (
                            <div className="form-width mx-auto flex justify-center my-3">
                              <DropDownWithSearch
                                data={item}
                                setAnswer={setAnswer}
                                formClass={formClass}
                                formValidation={item?.formValidation}
                              />
                            </div>
                          )}
                        {item?.type === "radio" &&
                          checkForQuestionToShow(item) && (
                            <div className="form-width mx-auto flex justify-center my-3">
                              <RadioSelector
                                data={item}
                                setAnswer={setAnswer}
                                formValidation={formValid}
                                formClass={formClass}
                              />
                            </div>
                          )}
                        {item?.type === "content" &&
                          checkForQuestionToShow(item) && (
                            <div className="form-width mx-auto flex justify-center my-3">
                              <ContentRenderer data={item} />
                            </div>
                          )}
                        {item?.type === "file" &&
                          checkForQuestionToShow(item) && (
                            <div className="form-width mx-auto flex justify-center my-3">
                              <UploadFile
                                data={item}
                                setAnswer={setAnswer}
                                formValidation={item?.formValidation}
                                formClass={formClass}
                              />
                            </div>
                          )}

                        {item?.type === "selectboxes" &&
                          checkForQuestionToShow(item) && (
                            <div className="form-width mx-auto flex justify-center my-3">
                              <CheckBoxSelector
                                data={item}
                                setAnswer={setAnswer}
                                formClass={formClass}
                                formValidation={item?.formValidation}
                              />
                            </div>
                          )}

                        {item?.type === "dynamicContentRenderer" &&
                          checkForQuestionToShow(item) && (
                            <div className="form-width mx-auto flex justify-center my-3">
                              <DynamicContentRenderer
                                data={item}
                                setAnswer={setAnswer}
                                formClass={formClass}
                                formValidation={item?.formValidation}
                              />
                            </div>
                          )}
                        {item?.type === "healthHistoryAllergyComponent" &&
                          checkForQuestionToShow(item) && (
                            <div className="form-width mx-auto flex justify-center my-3">
                              <HealthHistoryAllergyComponent
                                data={item}
                                setAnswer={setAnswer}
                                formClass={formClass}
                                formValidation={item?.formValidation}
                              />
                            </div>
                          )}

                        {item?.type === "healthHistoryMedicationComponent" &&
                          checkForQuestionToShow(item) && (
                            <div className="form-width mx-auto flex justify-center my-3">
                              <HealthHistoryMedicationComponent
                                data={item}
                                setAnswer={setAnswer}
                                formClass={formClass}
                                formValidation={item?.formValidation}
                              />
                            </div>
                          )}
                      </>
                    );
                  })}
              </>
            )}
          </>
        )}
        {openModal ? (
          <>
            <ModalBox
              openModal={true}
              title={modalTitle}
              SubTitle={modalSubtitle}
              hideClose={true}
              showButtons={false}
              showIcon={false}
              maxWidth={600}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="bottom-bar-shadow">
        <div className="flex justify-between pt-3 m-auto bottom-bar w-full">
          <button
            onClick={handleBack}
            className={
              firstPageFirstFormID === activeFormPageId
                ? "opacity-0 text_button cursor_default"
                : "text_button"
            }
          >
            Back
          </button>
          {formSchema &&
          formSchema[formSchema?.length - 1].id === activeFormPageId ? (
            <div onClick={handleSubmit} className="primary_button">
              Submit
            </div>
          ) : (
            <div onClick={handleNext} className="primary_button ">
              Next
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormRenderer;
